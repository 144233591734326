@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  background-color: #ffffff;

  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}
.noInternetDiv {
  min-height: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.noInternet {
  fill: none;
  width: 48px;
  height: 48px;
}
.loadingText {
  margin: 24px;
}
.contentWrapperForProductLayout {
  /* margin: 0 0 56px 0; */
  /* background-color: #f5f5f5; */
  @media (--viewportMedium) {
    width: 100%;
    max-width: 1056px;

    /* max-width: 1500px; */

    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: 1168px;
    width: 100%;
    margin: 72px auto;
    padding: 0px 24px;
    /* padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto; */
  }
}
.reviewDiv {
  @media (min-width: 992px) {
    min-width: 1200px;
  }
}
.detailAndBookingDiv {
  display: flex;
}
.mainColumnForProductLayout {
  background-color: #4a4a4a;

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 348px);
    /* flex-basis: calc(100% - 348px); */
    flex-basis: 60%;
    background-color: #4a4a4a;

    flex-shrink: 0;
    flex-grow: 1;
  }
}
.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    /* flex-basis: 300px; */
    flex-basis: 40%;

    flex-shrink: 0;
  }
}
.errorText {
  color: var(--matterColorAnti);
  margin: 24px;
}
.bookingPanelDiv {
  display: flex;
  flex-direction: column;
  flex-basis: 400px;
}
.shrBtn,
.shrBtnMobile {
  margin-left: auto;

  cursor: pointer;
  & span {
    margin-right: 8px;
  }
}
.shrBtn {
  margin-top: -50px;
  margin-bottom: 20px;
  @media (max-width: 1023.98px) {
    display: none;
  }
}
.shrBtnMobile {
  margin-top: 0px;
  margin-bottom: 0px;
  @media (min-width: 1024px) {
    display: none;
  }
}
.avgReviewText {
  font-size: 13px;
  font-weight: 400;
  /* color: var(--matterColorAnti); */
  color:var(--matterColorGrey);
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 767.98px) {
    font-size: 14px;
  }
}
.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}
.actionBarDiv {
  /* margin-bottom: 30px; */
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 0 20px 0;
    font-size: 12px;
  }
}
.actionBar {
  /* position: absolute; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  top: 13px;
  left: 13px;
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 10px 12px 10px 24px;

  @media (--viewportMedium) {
    margin: 10px 12px 10px 24px;
  }

  @media (min-width: 320px) and (max-width: 575.98px) {
    margin: 5px 10px;
    font-size: 12px !important;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);

  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px !important;
  }
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px !important;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 10px 24px 10px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 10px 24px 10px 12px;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px !important;
    margin: 5px 10px;
    padding: 0;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}
.viewPhotoButtonContainer {
  display: flex;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0 24px 0 0;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    padding: 0 36px 0 0;
    margin: 0 0px 117px;
    max-width: calc(100% - 420px);
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

.mainContent {
  /* flex-basis: 100%; */
  margin-bottom: 23px;
  max-width: 100%;
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin-top: 20px;
  }
  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 433px); */
    /* flex-basis: calc(100% - 433px); */
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    /* margin-top: 79px; */
    /* margin-left: 60px; */
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #efefef;

    padding-left: 40px;

    /* The calendar needs 312px */
    flex-basis: 400px;
    flex-shrink: 0;
  }

  & > div {
    & > div {
      @media (min-width: 320px) and (max-width: 767.98px) {
        display: block !important;
      }
    }
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  /* margin-left: 24px; */
  /* margin-top: -72px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */

  /* Flex would give too much width by default. */
  width: auto;

  @media (--viewportMedium) {
    /* position: absolute; */
    /* top: -112px; */
    margin-left: 0;
    margin-top: 0;
  }
  /* & > div::nth-child(1) {
    width: 85px !important;
    height: 85px !important;
    justify-content: left;
  } */
  & div {
    width: 85px !important;
    height: 85px !important;
    justify-content: left;

    & img {
      width: 70px;
      height: 70px;
      border: 1px solid #e7e7e7;
    }

    & svg {
      width: 70px;
      height: 50px;
    }
  }
}
.loadingBody {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 500px;
}

.loadingImgBody {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (min-width: 575px) {
    min-height: 500px;
  }
}

.loadingIcon {
  width: 48px;
  height: 48px;
  margin: auto;
}

.loadingImgIcon {
  width: 100%;
  height: 100%;
}
.avatarMobile {
  display: flex;
  /* width: 96px;
  height: 96px; */

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  /* width: 152px; */
  /* height: 152px; */

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: underline;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 24px;
  }
}

.descriptionTitle {
  /* Font */
  composes: h3 from global;
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: capitalize;
  display: flex;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 10px;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 14px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}
.hDivider {
  display: block;
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 5px;
}
.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorTextLight);
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 14px;
  }
}
.featureDiv {
}
.featureDetail,.featureDetailForReturn {
  line-height: 48px;
  display: flex;
  flex-direction: column;
  & :nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: var(--matterColorTextLight);
    @media (min-width: 320px) and (max-width: 475.98px) {
      font-size: 12px;
    }
  }
  & svg {
    height: 10px;
    margin-right: 5px;
  }
  & :nth-child(2) {
    /* margin-left: auto; */
    font-size: 14px;
    font-weight: 400;
    color: var(--matterColorTextLight);
    /* max-width: 30%; */
    overflow: hidden;
    /* text-overflow: ellipsis;
    white-space: nowrap; */
    @media (min-width: 320px) and (max-width: 476px) {
      font-size: 12px;
    }
  }
}
 
.listStyle {
  list-style-type: disc;
  margin-left: 30px;
  & li {
    font-weight: 400;
  }
}
.avlDiv {
  max-width: 100% !important;
  overflow: initial !important;
  display: grid;
  grid-template-columns: auto auto auto;
  @media (max-width: 767px) {
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: column; */
    grid-template-columns: auto auto;
  }
}
.wkday,
.avlDay {
  margin-right: 30px;

  font-size: 14px !important;
  font-weight: 400 !important;
  @media (max-width: 374px) {
    font-size: 12px !important;
  }
}
.wkday {
  color: #9a9a9a !important;
}
.avlDay {
  color: #000000 !important;
  & svg {
    & path {
      fill: none;
    }
  }
}
.avlsvgCont {
  & path {
    fill: none;
  }
}

.notavlsvgCont {
  /* display: none; */
  display: inline-block;
  & path {
    stroke-width: 0;
    fill: none;
  }
  /* @media (max-width: 767px) {
    display: inline-block;
    & path {
      stroke-width: 0;
      fill: none;
    }
  } */
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  color: var(--matterColorTextLight);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 14px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorTextLight);
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 14px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  composes: h3 from global;
  color: var(--matterColorTextLight);
  font-size: 18px;
  font-weight: 600;
  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 14px;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 30px 0 100px 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  /* max-width: 640px; */
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
  & div {
    max-width: 100% !important;

    & img {
      width: 100%;
    }
  }
}
.mapDiv {
  width: 100% !important;
}
.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.arrowBtn {
  fill: none;
  & path {
    stroke: #ffffff;
  }
}
.svgIcons {
  fill: none;
  margin: 0 10px;
  @media (max-width: 267px) {
    display: none;
  }
}
.favBtn {
  fill: none;
  margin: 0 10px;
  cursor: pointer;
  & path {
    stroke: var(--marketplaceColorDark);
  }
}
.unfavBtn {
  cursor: pointer;

  fill: var(--marketplaceColor);

  margin: 0 10px;
  & path {
    stroke: var(--marketplaceColor);
  }
}

.wrapperClassName {
  & div {
    & > div {
      padding: 0;
      /* margin-top: 20px; */
      /* position: relative; */
      /* & :nth-child(2) {
        & div {
          & img {
            max-height: calc(100% - 80px) !important;
          }
        }
      } */
    }
  }
}
.imgGalleryDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 767.98px) {
    padding: 0 20px;
  }
}
.cstmctrl {
  position: absolute !important;
  width: 100%;
  display: flex;
  z-index: 9;
  top: -20px;
  /* left: 20px; */
}
.roundBtn {
  /* margin-left: auto; */
  border-radius: 50%;
  background-color: #ffffff;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: absolute;
  justify-content: center;
  top: 0px;
  right: -20px;
  cursor: pointer;
  z-index: 9;
  @media (max-width: 767px) {
    top: 30px;
    right: 20px;
  }
}
/* .viewPhotoBtnDiv {
  right: 35px;
  width: 105px;
  z-index: 9;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 0;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  bottom: -17px;
} */
.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  margin-left: auto;
  /* Position and dimensions */
  position: absolute;
  /* bottom: 80px;    //outside the image gallery */
  /* bottom: -290px;
  right: 24px; */
  /* left: 5px; */
  /* top: 5px; */
  /* top: 300px; */
  bottom: 25px;
  right: 20px;
  margin: 0;
  padding: 8px 13px 6px 13px;
  z-index: 1;
  /* Colors */
  background-color: #fff;
  /* bottom: -17px; */
  color: #000;
  /* Borders */
  border: none;
  border-radius: var(--borderRadius);
  font-weight: 400;
  cursor: pointer;
  transition: var(--transitionStyleButton);
  /* @media (min-width: 767px) {
    top: 420px;
  } */
  &:hover {
    /* background-color: var(--marketplaceColor);
    color: var(--matterColorLight); */
    box-shadow: 2px 2px 10px 2px rgb(0 0 0 / 15%);
  }
}
.topDiv {
  display: flex;
  margin-top: -50px;
  margin-bottom: 20px;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin: 20px 0px 10px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 20px 20px 10px;
  }
}
.backBtn {
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
  & svg {
    margin-right: 10px;
    display: inline-block;
    @media (min-width: 320px) and (max-width: 575.98px) {
      width: 20px;
    }
  }
}
.gallerydiv {
  /* margin: 20px auto 0;
  max-width: 776px; */
  /* padding: 0 24px; */
  @media (max-width: 767px) {
    margin: 0px;
  }
}

.okBtn {
  margin-top: 40px;
}

.successMsg {
  margin-top: 20px;
}
