@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  margin: 50px auto 72px;
  max-width: 1040px;
  padding: 0 40px;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  @media (min-width: 1400px) {
    max-width: 1300px;
    padding: 0;
  }
  @media (min-width: 1992px) and (max-width: 1399.98px) {
    max-width: 1240px;
    padding: 0 20px 30px;
    margin: 30px auto 30px;
  }
  @media (min-width: 575px) and (max-width: 1991.98px) {
    padding: 0 24px 30px;
    margin: 30px auto 20px;
  }
  @media (min-width: 320px) and (max-width: 574.98px) {
    padding: 0 20px 30px;
    margin: 30px auto 20px;
  }
}

.txInfo {
  @media (--viewportLarge) {
    padding-right: 20px;
    border-right: 1px solid #efefef;
    flex-basis: 48%;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 40px;
    margin-bottom: 20px;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  /* margin-left: 24px;
  margin-top: -30px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */
  /* Flex would give too much width by default. */
  /* width: 60px; */

  /* @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  } */
  & img {
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
    }
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  /* display: none; */

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  /* margin: 29px 24px 0 24px; */
  margin-top: 0;
  /* @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  } */
}

.headingSale {
  margin-top: 0;

  /* margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  } */
}

.mainTitle,
.subtitle {
  /* display: block; */
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 25px;
    line-height: 31px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 20px;
    line-height: 21px;
  }
}
.subtitle {
  color: var(--marketplaceColor);
}
.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  /* border-left: 1px solid #efefef; */
  /* padding-left: 40px; */
  flex-basis: 47%;
  @media (min-width: 1024px) {
    position: sticky;
    left: 52.65%;
    width: calc(50% - 8%);
    max-width: 400px;
    height: calc(100vh - 18%);
    background: #fff;
    /* z-index: 999; */
    top: 122px;
    /* position: fixed; */
  }

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */
}

.detailCard {
  @media (--viewportLarge) {
    /* position: sticky; */
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    /* width: 409px; */
    /* background-color: var(--matterColorLight); */
    /* border: 1px solid var(--matterColorNegative);
    border-radius: 2px; */
    z-index: 1;
  }
}
.asideTopDiv {
  display: flex;
  @media (min-width: 320px) and (max-width: 1023.98px) {
    margin-top: 20px;
  }
}
.bookingDetailDiv {
  margin-top: 30px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  background-color: var(--matterColorLight);
  /* @media (max-width: 1023px) {
    margin-bottom: 48px;
    padding-bottom: 24px;
  }
  @media (max-width: 1023px) {
    border-width: 0;
    margin: 0px;
    padding-bottom: 0px;
  }

  @media (--viewportLarge) {
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    background-color: var(--matterColorLight);
  } */
}

.enquiredBookingDetailsDiv {
  @media (max-width: 1023.98px) {
    border-width: 0px;
    border-radius: 0px;
  }
}

.notBookingProviderMsg {
  padding: 24px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  background-color: var(--matterColorLight);
  font-size: 19px;
  line-height: 32px;
  font-weight: 500;
  text-transform: capitalize;

  @media (--viewportLarge) {
    border-width: 0px;
    border-radius: 0px;
    margin-top: 0px;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  /* display: none; */
  line-height: 25px;
  @media (--viewportLarge) {
    display: block;
    /* margin: 0 48px 33px 48px; */
    margin: 0 25px;
  }

  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin: 0 20px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 0 15px;
  }
}
.soldBy {
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
}
.shippingAddressDiv {
  margin-top: 46px;
}
.addressDetailDiv {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 10px;
}
.detailCardTitle {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 0;
  }
  @media (min-width: 320px) and (max-width: 1023.98px) {
    margin: 0;
    font-size: 14px;
  }
}

.detailCardSubtitle {
  composes: h5 from global;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  padding: 24px;
  /* border: 1px solid var(--matterColorNegative); */
  border-radius: 8px;
  background-color: var(--matterColorLight);
  margin-bottom: 48px;

  /* @media (--viewportLarge) {
    border-width: 0;
    border-radius: 0px;
  } */

  /* @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
  }
  @media (--viewportLarge) {
    padding: 24px 32px 47px 32px;
  } */
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorText);
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 0;

  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin: 30px 0px 24px 0px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 25px 0px 24px 0px;
  }
}
.topLine {
  /* display: block; */
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--matterColorDark);
  &:hover {
    color: var(--marketplaceColorDark);
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    padding: 0;
    margin-bottom: 20px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    /* padding: 0 24px; */
    margin-bottom: 30px;
  }
}
.icon {
  fill: transparent;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-left: 10px;
  flex-shrink: 0;
  & path {
    stroke: var(--matterColorAnti);
  }
}
/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
  @media (min-width: 320px) and (max-width: 1023.98px) {
    margin: 0px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  z-index: unset;
  position: static;
  box-shadow: none;
  width: auto;
  margin: 0 28px 0 28px;
  padding: 0;
  /* Position action button row above the footer */
  /* z-index: 9;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px; */

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  /* transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white; */

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 28px 0 28px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 16px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
  & button:last-child {
    margin: 0 0;
  }
}

.actionError {
  composes: h5 from global;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;
  margin: -40px 0px 60px;

  /* @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  } */
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: block;
  margin-bottom: 48px;

  @media (--viewportLarge) {
    /* display: block; */
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
/* .bookingPanel {
  margin: 37px 28px 37px 28px;
  & div {
    margin: 0;
    padding: 0;
  }
} */

.bookingPanel {
  padding: 36px 24px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  background-color: var(--matterColorLight);
  @media (--viewportLarge) {
    display: block;
    /* margin-top: 79px; */
    /* margin-left: 60px; */
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #efefef;

    /* The calendar needs 312px */
    flex-basis: 400px;
    flex-shrink: 0;
  }

  & > div {
    & > div {
      @media (min-width: 320px) and (max-width: 767.98px) {
        display: block !important;
      }
    }
  }

  @media (max-width: 1023.98px) {
    border-width: 0px;
    border-radius: 0px;
  }
}

/* Font */

/* .bookingTitle {

  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
} */

.actionsMainWrapper {
  & .disableMsg {
    color: var(--failColor);
    text-align: center;
    margin-top: -4px;
  }
}

.sizeForm {
  color: #000;
  & input {
    display: none;
  }
  & .sizeLabelWrapper {
    display: flex;
    flex-grow: 1;
    margin: 8px;
    justify-content: space-between;
  }
  & .sizeLabel {
    margin: 8px 0px;
    padding: 16px 8px;
    border: 1px solid var(--marketplaceColor);
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 45%;
    flex-basis: 45%;

    & span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-transform: capitalize;
      margin-left: 8px;
    }
  }
}
.error {
  color: var(--failColor);
  font-size: 16px;
  line-height: 24px;
}
.shippingInfo {
  border: 1px solid var(--marketplaceColor);
  padding: 16px;
  margin: 16px 0px;
  & .title {
    color: #000;
    font-size: 18px;
    line-height: 36px;
    border-bottom: 1px solid var(--matterColorNegative);
    margin-bottom: 8px;
  }

  & .body {
    display: flex;
    flex-direction: row;
    color: #000;
    flex-wrap: wrap;

    & .info {
      display: flex;
      flex-direction: row;
      padding: 8px;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      flex-grow: 1;

      & > span {
        margin-left: 8px;
      }

      & > svg {
        flex-shrink: 0;
      }
    }
  }
  & .actionBtn {
    display: flex;
    flex-direction: row;
    padding: 8px 0px;
    align-items: center;

    @media (max-width: 567px) {
      flex-direction: column;
    }

    & .downloadBtn {
      max-width: 31% !important;
      flex-basis: 31% !important;
      padding: 8px 16px;
      min-height: 48px !important;
      margin: 8px 0px;
      margin-right: 16px;
      @media (max-width: 567px) {
        margin-right: 0px;
        max-width: 100% !important;
        min-width: 100% !important;
        flex-basis: 100% !important;
      }
    }

    & .link {
      max-width: 31% !important;
      flex-basis: 31% !important;

      padding: 8px;
      background-color: var(--marketplaceColor);
      color: #fff;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      margin: 0px !important;

      text-decoration: none !important;
      justify-content: center;
      min-height: 44px;
      margin-right: 16px !important;

      /* max-height: 44px; */

      &:hover,
      &:active {
        background-color: var(--marketplaceColorDark);
        color: var(--matterColorLight);
      }

      margin: 8px;
      @media (max-width: 567px) {
        margin-right: 0px !important;

        max-width: 100% !important;
        flex-basis: 100% !important;
        min-width: 100% !important;
      }
    }
  }

  & .threeActionBtn {
    /* justify-content: space-between; */
  }
}

.parcel {
  color: #000;
  padding: 8px;

  & > div:last-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & > span {
      flex-basis: 48%;
      padding: 8px;
      max-width: 48%;
      margin: 4px;
      border: 1px solid var(--matterColorNegative);
      font-weight: 400;

      @media (max-width: 575px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}

.inputs {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding: 12px 18px;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    /* height: 48px; */
    background-color: #ffffff;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 37 px;
    }
    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      transform: translateY(-160%) scale(0.95) !important;
    }
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}

.textInput {
  flex-shrink: 0;
  margin: 16px 0px 16px;
  position: relative;
  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 30px;
    /* resize: auto; */
    resize: vertical !important;
    min-height: 77px;

    max-height: 350px;
    border: 1px solid var(--matterColorText);
    /* border: 1px solid var(--marketplaceColor); */
    padding: 16px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
    @media (min-width: 320px) and (max-width: 575.98px) {
      border-radius: 15px;
      max-height: 146px;
      /* height: 77px; */
      font-size: 14px;
    }
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}
.categoryReactSelect {
  width: 100%;
  /* margin-bottom: 20px; */
  border-color: var(--matterColorDark) !important;
  /* display: flex; */
  margin: 20px 0;
  & > div:nth-child(3) {
    /* border-color: var(--matterColorDark) !important;
    border-width: 0px !important;*/
    border-radius: 30px !important;
    border: none;
    box-shadow: none !important;
    height: 42px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 37px;
    }
    & > div:nth-child(1) {
      overflow: visible;
    }

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}
.reactSelect {
  width: 48%;
  margin-bottom: 20px;
  border-color: var(--matterColorDark) !important;
  /* display: flex; */

  & > div:nth-child(3) {
    border-color: var(--matterColorDark) !important;
    border-width: 0px !important;
    /* border-radius: 0px !important; */
    box-shadow: none !important;
    height: 57px;

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}
.cateSelect {
  border: 1px solid black !important;
  border-radius: 30px;
  padding-left: 10px;
  min-height: 48px;
  padding-top: 2px;
  & .valueContainer {
    & .value {
      & > div:first-child {
        margin-left: 0px !important;
      }
    }
  }
}

.disabledSelect {
  opacity: 0.2;
  cursor: not-allowed !important;

  & > div:nth-child(3) {
    background-color: #fff !important;
  }
}
.dropDownIndicatior {
  color: var(--matterColorDark);
  font-size: 22px;
  cursor: pointer;
  position: relative;
  /* z-index: 21; */
  fill: none;
  margin: 0 20px 0 0;
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin: 0 15px 0 0;
  }
}
.disabledIndicator {
  color: var(--wardrobeColorBrown);
}
.menu {
  border-radius: 0px !important;
  margin-top: 1px !important;
  width: calc(100% + 2px) !important;
  left: -1px !important;

  z-index: 20 !important;
  /* margin-bottom: 50px; */

  & > div:first-child {
    border-top-width: 0px;
    background-color: #fff;
    overflow-y: auto;
    scrollbar-width: default;
    scrollbar-color: #e1e1e1 transparent;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--matterColorDimGrey);
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--wardrobeColorDimGrey);
      border-width: 0px;
      border-radius: 10px;
    }

    & > div {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      height: 61px;
      font-size: 16px;
      /* Dimensions */
      width: 100%;
      margin: 0;
      padding: 4px 24px;
      /* Layout details */
      color: var(--matterColorText);
      text-align: left;
      transition: var(--transitionStyleButton);

      /* &:hover {
        background-color: var(--marketplaceColorLight);
      } */
      &:hover,
      &:active,
      &:focus {
        color: var(--matterColorText) !important;
        background-color: var(--marketplaceColorLight);
      }

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        height: 1px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--matterColorPaleWhite);
      }
    }

    & > div:first-child {
      color: var(--wardrobeColorBrown) !important;
      background-color: var(--matterColorLight) !important;

      &:hover {
        background-color: var(--marketplaceColorLight) !important;
      }
    }

    & > div:last-child,
    & > div:first-child {
      &::after {
        background-color: transparent;
      }
    }
  }
}
.multiValueContainer {
  /* height:  !important; */
  height: auto !important;
  min-height: 42px !important;

  & .emptyPlaceholder {
    top: 10px !important;
    left: 0px !important;
  }

  & .selectedPlaceholder {
    top: -3px !important;
    left: calc(-10% + 2px) !important;
  }
  & > span:first-child {
    position: relative;
    display: block;
    left: -16px;
    line-height: 20px;
    top: -2px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    top: -2px;
    & > div:not(:last-child) {
      margin: 0px 3px;
      display: inline-block;
      position: relative;
      top: 0px !important;

      & > div:first-child {
        font-size: 14px;
        line-height: 17px;
        padding: 0px;
        background-color: #fff;
        overflow: unset !important;
      }
      & > div:last-child {
        display: none;
      }
    }
    & > div {
      position: relative;
      &::after {
        content: ',';
        position: absolute;
        right: -4px;
        bottom: -6px;
      }
    }

    & > div:nth-last-child(2) {
      &::after {
        display: none;
      }
    }
    & > div:nth-child(n + 4) {
      display: none;
    }
    & > div:nth-child(3) {
      &::after {
        content: '...';
        right: -16px;
        font-weight: 500;
        font-size: 18px;
      }
    }
    & > input:last-child {
      margin-left: 10px;
      position: absolute;
    }
  }
}
.valueContainer {
  /* height: 40px;
  padding: 2px; */
  position: relative;
  height: 42px;
  width: 100%;
  cursor: pointer;
  padding-left: 0.1rem;
  & .placeholder {
    /* color: var(--wardrobeColorDarkGray); */
    color: #b5b5b5;
    font-weight: var(--fontWeightSemiBold);
    /* font-weight: 400; */
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 0px;
    /* padding-left: 0.4rem; */
    z-index: 2;

    @media (max-width: 767px) {
      top: 8px;
    }
  }

  & .selectedPlaceholder {
    transform: scale(0.9);
    top: -20px;
    left: -6px;
    background-color: #ffffff;
    padding: 0 2px;
    @media (min-width: 320px) and (max-width: 767.98px) {
      top: -15px;
    }
  }
  & .selectedSCPlaceholder {
    transform: scale(0.8);
    top: -6px;
    left: -15px;
  }
  & .value {
    & > div:first-child {
      position: relative;
      top: 4px;
      font-size: 16px;
      color: var(--matterColorText);
      text-transform: uppercase;
      @media (min-width: 320px) and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    & > div:last-child {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: -2px;
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
      top: 0px;
      left: 0px;
    }
  }

  & .blurredValue {
    & > div:first-child {
      color: var(--wardrobeColorDarkGray);
    }
  }
}
.catSelectedPlaceholder {
  & .placeholder {
    @media (max-width: 767px) {
    }
  }

  & .selectedPlaceholder {
    left: -6px;
  }

  & .value {
    & > div:first-child {
    }

    & > div:last-child {
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
    }
  }

  & .blurredValue {
    & > div:first-child {
    }
  }
}
.warningMsg {
  color: red;
  text-align: center;
}

.shippmentWrapper {
  padding: 48px 0px 8px;

  & .addressWrapper {
    & .addressHeading {
      display: flex;

      & > span {
        color: #000;
        font-size: 16px;
        line-height: 24px;
      }

      & .addressShowBtn {
        margin-left: auto;
      }
    }
    & .addressFormWrapper {
      padding: 8px 0px;
    }
    & .text {
      border: 1px solid var(--matterColor);
      font-size: 16px;
      line-height: 24px;
      color: var(--matterColor);
      display: block;
      padding: 12px 16px;
      border-radius: 48px;
    }
  }

  & .itemDetailsWrappper {
    & li {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      line-height: 24px;
      padding: 4px 0px;
      &:first-child {
        font-size: 17px;
        line-height: 26px;
        font-weight: 500;
      }
    }
  }
}

.infoHeading {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  color: #000;
}

.parcelHeading {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  color: #000;
  display: flex;
}

.parcelDetail {
  display: flex;
  flex-direction: column;
}

.detailsBtn {
  margin-left: auto;
}

.modalContainer {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  /* border-radius: var(--borderRadius); */
  border-bottom: none;

  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 676px;
    flex-grow: 0;
    min-height: auto;
    height: auto;

    padding: var(--modalPadding);

    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    /* border-bottom: 8px solid var(--marketplaceColor); */
  }
  & ol {
    padding-left: 16px;
    & li {
      padding: 8px 0px;
      border-bottom: 1px solid var(--matterColorNegative);

      &:last-child {
        border-color: transparent;
      }
    }

    & a {
      text-decoration: none !important;
      border-bottom: 1px solid var(--marketplaceColor);
      border-color: transparent;

      &:hover,
      &:focus {
        border-color: var(--marketplaceColor);
      }
    }

    & svg path {
      stroke: var(--marketplaceColor);
    }
  }
}

.eeiHelperText {
  margin-top: -10px;
  color: var(--marketplaceColor);

  & svg {
    width: 24px;
    height: 24px;
  }

  & > span {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}

.detailsCheckWrapper {
  margin: -32px 0px 32px;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  align-items: center;

  & svg {
    fill: transparent;
  }

  & .statusText {
    margin: 4px 0px;
    font-size: 15px;
    line-height: 28px;
  }

  & .missing {
    color: var(--failColor);

    & svg {
      position: relative;
      top: -1px;
      margin-right: 16px;
      & path {
        stroke: var(--failColor);
      }
    }
  }

  & .filled {
    color: var(--successColor);

    & svg {
      margin-right: 16px;

      & path {
        stroke: var(--successColor);
      }
    }
  }

  & .checkBtn {
    color: var(--matterColor);
    margin-top: 16px;
    text-decoration: none !important;
    display: flex;
    align-items: center;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    & svg {
      stroke: var(--marketplaceColor);
    }

    & .checkStatus {
      border: 2px solid var(--matterColorDarkGray);
      border-radius: 4px;
      transform: scale(0.8);
      margin-right: 8px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        fill: transparent;
        width: 16px;
        height: 16px;

        & path {
          stroke: var(--marketplaceColor);
        }
      }
    }
  }
}

.courierDetails {
  display: flex;
  justify-content: space-between;

  & > * {
    flex-basis: 48%;
    max-width: 48%;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    & > * {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.devlieryDataModalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.deliveryDateForm {
  margin-top: auto;
  margin-bottom: auto;
}

.pickupAddressMessage {
  color: var(--marketplaceColor);
}
