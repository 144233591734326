@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (min-width: 320px) and (max-width: 575.98px) {
    padding-bottom: 80px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.dropDownIndicatior {
  color: var(--matterColorDark);
  font-size: 22px;
  cursor: pointer;
  position: relative;
  /* z-index: 21; */
  fill: none;
  margin: 0 20px 0 0;
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin: 0 15px 0 0;
  }
}
.disabledIndicator {
  color: var(--wardrobeColorBrown);
}
.menu {
  border-radius: 0px !important;
  margin-top: 1px !important;
  width: calc(100% + 2px) !important;
  left: -1px !important;

  z-index: 20 !important;
  /* margin-bottom: 50px; */

  & > div:first-child {
    border-top-width: 0px;
    background-color: #fff;
    overflow-y: auto;
    scrollbar-width: default;
    scrollbar-color: #e1e1e1 transparent;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--matterColorDimGrey);
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--wardrobeColorDimGrey);
      border-width: 0px;
      border-radius: 10px;
    }

    & > div {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      height: 61px;
      font-size: 16px;
      /* Dimensions */
      width: 100%;
      margin: 0;
      padding: 4px 24px;
      /* Layout details */
      color: var(--matterColorText);
      text-align: left;
      transition: var(--transitionStyleButton);

      /* &:hover {
        background-color: var(--marketplaceColorLight);
      } */

      &:hover,
      &:active,
      &:focus {
        color: var(--matterColorText) !important;
        background-color: var(--marketplaceColorLight);
      }

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        height: 1px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--matterColorPaleWhite);
      }
    }

    & > div:first-child {
      color: var(--wardrobeColorBrown) !important;
      background-color: var(--matterColorLight) !important;

      &:hover {
        background-color: var(--marketplaceColorLight) !important;
      }
    }

    & > div:last-child,
    & > div:first-child {
      &::after {
        background-color: transparent;
      }
    }
  }
}
:global(.css-1nmdiq5-menu) {
  z-index: 14 !important;
  margin: unset !important;
}
.menuForReturnPolicy {
  border-radius: 0px !important;
  margin-top: 1px !important;
  width: calc(100% + 2px) !important;
  left: -1px !important;

  z-index: 20 !important;
  /* margin-bottom: 50px; */

  & > div:first-child {
    border-top-width: 0px;
    background-color: #fff;
    overflow-y: auto;
    scrollbar-width: default;
    scrollbar-color: #e1e1e1 transparent;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--matterColorDimGrey);
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--wardrobeColorDimGrey);
      border-width: 0px;
      border-radius: 10px;
    }

    & > div {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      height: 61px;
      font-size: 16px;
      min-height: 96px;
      /* Dimensions */
      width: 100%;
      margin: 0;
      padding: 4px 24px;
      /* Layout details */
      color: var(--matterColorText);
      text-align: left;
      transition: var(--transitionStyleButton);

      /* &:hover {
        background-color: var(--marketplaceColorLight);
      } */

      &:hover,
      &:active,
      &:focus {
        color: var(--matterColorText) !important;
        background-color: var(--marketplaceColorLight);
      }

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        height: 1px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--matterColorPaleWhite);
      }
    }

    & > div:first-child {
      color: var(--wardrobeColorBrown) !important;
      background-color: var(--matterColorLight) !important;

      &:hover {
        background-color: var(--marketplaceColorLight) !important;
      }
    }

    & > div:last-child,
    & > div:first-child {
      &::after {
        background-color: transparent;
      }
    }
  }
}

.multiValueContainer {
  /* height:  !important; */
  height: auto !important;
  min-height: 42px !important;

  & .emptyPlaceholder {
    top: 10px !important;
    left: 0px !important;
  }

  & .selectedPlaceholder {
    top: -3px !important;
    left: calc(-10% + 2px) !important;
  }
  & > span:first-child {
    position: relative;
    display: block;
    left: -16px;
    line-height: 20px;
    top: -2px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    top: -2px;
    & > div:not(:last-child) {
      margin: 0px 3px;
      display: inline-block;
      position: relative;
      top: 0px !important;

      & > div:first-child {
        font-size: 14px;
        line-height: 17px;
        padding: 0px;
        background-color: #fff;
        overflow: unset !important;
      }
      & > div:last-child {
        display: none;
      }
    }
    & > div {
      position: relative;
      &::after {
        content: ',';
        position: absolute;
        right: -4px;
        bottom: -6px;
      }
    }

    & > div:nth-last-child(2) {
      &::after {
        display: none;
      }
    }
    & > div:nth-child(n + 4) {
      display: none;
    }
    & > div:nth-child(3) {
      &::after {
        content: '...';
        right: -16px;
        font-weight: 500;
        font-size: 18px;
      }
    }
    & > input:last-child {
      margin-left: 10px;
      position: absolute;
    }
  }
}
.valueContainer {
  /* height: 40px;
  padding: 2px; */
  position: relative;
  height: 40px;
  width: 100%;
  cursor: pointer;
  padding-left: 0.1rem;
  /* margin-top: 4px; */
  margin-left: 2px;
  & .placeholder {
    /* color: var(--wardrobeColorDarkGray); */
    color: #b5b5b5;
    font-weight: var(--fontWeightSemiBold);
    /* font-weight: 400; */
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 0px;
    /* padding-left: 0.4rem; */
    z-index: 2;

    @media (max-width: 767px) {
      top: 8px;
    }
  }

  & .selectedPlaceholder {
    /* transform: scale(0.9); */
    transform: translateY(-145%) scale(0.95) !important;
    top: 26px;
    left: -6px;
    background-color: #ffffff;
    padding: 0 2px;
    @media (max-width: 767px) {
      transform: translateY(-155%) scale(0.95) !important;
      top: 20px;
    }
  }
  & .selectedSCPlaceholder {
    transform: scale(0.8);
    top: -6px;
    left: -15px;
  }
  & .value {
    & > div:first-child {
      position: relative;
      font-size: 16px;
      color: var(--matterColorText);
      text-transform: uppercase;
      @media (max-width: 767px) {
        top: 4px;
      }
      @media (min-width: 320px) and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    & > div:last-child {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: -2px;
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
      top: 0px;
      left: 0px;
    }
  }

  & .blurredValue {
    & > div:first-child {
      color: var(--wardrobeColorDarkGray);
    }
  }
}
.catSelectedPlaceholder {
  & .placeholder {
    @media (max-width: 767px) {
    }
  }

  & .selectedPlaceholder {
    left: -6px;
  }

  & .value {
    & > div:first-child {
    }

    & > div:last-child {
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
    }
  }

  & .blurredValue {
    & > div:first-child {
    }
  }
}
.SCselectedPlaceholder {
  & .placeholder {
    @media (max-width: 767px) {
    }
  }

  & .selectedPlaceholder {
    left: -6px;
  }

  & .value {
    & > div:first-child {
    }

    & > div:last-child {
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
    }
  }

  & .blurredValue {
    & > div:first-child {
    }
  }
}
.listingSelectedPlaceholder {
  & .placeholder {
    @media (max-width: 767px) {
    }
  }

  & .selectedPlaceholder {
    left: -3px;
  }

  & .value {
    & > div:first-child {
    }

    & > div:last-child {
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
    }
  }

  & .blurredValue {
    & > div:first-child {
    }
  }
}
.accomodationFeatureSelectContainer {
  & .placeholder {
    top: 11px;
  }

  /* & .selectedPlaceholder {
    top: -11px;
  } */
  & .selectedSCPlaceholder {
  }
  & .value {
    & > div:first-child {
      top: 11px !important;
    }

    & > div:last-child {
      top: 6px;
      left: 0px;
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
      /* top: 0px;
      left: 0px; */
    }
  }

  & .blurredValue {
    & > div:first-child {
      /* color: var(--wardrobeColorDarkGray); */
    }
  }
}

.categoryReactSelect {
  width: 100%;
  /* margin-bottom: 20px; */
  border-color: var(--matterColorDark) !important;
  /* display: flex; */
  margin: 20px 0;
  & > div:nth-child(3) {
    /* border-color: var(--matterColorDark) !important;
    border-width: 0px !important;*/
    border-radius: 30px !important;
    border: none;
    box-shadow: none !important;
    height: 42px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 42px;
    }
    & > div:nth-child(1) {
      overflow: visible;
      min-height: 42px;
    }

    & > div:nth-child(2) {
      height: 42px;
    }

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}
.reactSelect {
  width: 48%;
  margin-bottom: 20px;
  border-color: var(--matterColorDark) !important;
  /* display: flex; */

  & > div:nth-child(3) {
    border-color: var(--matterColorDark) !important;
    border-width: 0px !important;
    /* border-radius: 0px !important; */
    box-shadow: none !important;
    height: 57px;

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}
.cateSelect,
.cateSelectForPrice {
  border: 1px solid black;
  border-radius: 30px;
  padding-left: 10px;
  /* margin: 8px 0; */
  & .valueContainer {
    & .value {
      & > div:first-child {
        margin-left: 0px !important;
        top: 3px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
        white-space: normal;
        @media (max-width: 767px) {
          top: 7px;
        }
      }
    }
  }
}
.cateSelectForPrice {
  margin: 8px 0;
}
.priceReactSelect {
  @media (min-width: 768px) {
    flex-basis: 49%;
    margin: 8px 0;
  }
}
.priceSelect {
  border: 1px solid black;
  border-radius: 30px;
  padding-left: 10px;
  /* height: 42px; */
  & .valueContainer {
    & .value {
      & > div:first-child {
        margin-left: 0px !important;
        top: 3px;
        @media (max-width: 767px) {
          top: 7px;
        }
      }
    }
  }
}
.view {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 11px;
  right: 15px;
  fill: transparent;
  & path {
    stroke: var(--matterColorText);
  }
  &:hover {
    cursor: pointer;

    & .hide {
      display: block;
    }
  }
}
.hide {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 11px;
  right: 15px;
  fill: transparent;
  & path {
    stroke: var(--matterColorText);
  }
}
.termLabel {
  display: flex;
}
.checkBox {
  flex-shrink: 0;
}
.termsText {
  composes: marketplaceModalHelperText from global;
}
.termsText {
  composes: marketplaceModalHelperText from global;
  /* margin-bottom: 20px; */
  text-align: left;
  display: flex;
  flex-direction: column;
  /* align-items: center !important; */
  color: var(--matterColorText);
  @media (--viewportMedium) {
    margin-top: 24px;

    margin-bottom: 20px;
  }
  & label {
    font-weight: 400;
    margin-bottom: 10px;

    & svg {
      margin-right: 10px;
      fill: none;
      width: 16px;
      height: 16px;
    }
  }
  /* & input {
    width: auto !important;
    margin-right: 10px;
  }  */
  & span {
    font-size: 14px;
    font-weight: 600;
    & label {
      display: none;
    }
    & a {
      & span {
        font-weight: 400;
        font-size: 14px;
        color: var(--matterColorText);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.inputBox {
  display: none;
}

.textInput {
  flex-shrink: 0;
  margin: 16px 0px 16px;
  position: relative;
  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 30px;
    /* resize: auto; */
    resize: vertical !important;
    min-height: 77px;

    max-height: 350px;
    border: 1px solid var(--matterColorText);
    /* border: 1px solid var(--marketplaceColor); */
    padding: 20px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
    @media (min-width: 320px) and (max-width: 575.98px) {
      border-radius: 15px;
      max-height: 146px;
      height: 77px;
      font-size: 14px;
    }
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}

.divLabel {
  display: block;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin: 20px 0;
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  flex-basis: 30%;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.cancelButton {
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
  flex-basis: 30%;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.lowerDiv {
  display: flex;
  align-items: center;
  margin: 50px 0;

  /* flex-direction: column; */
  @media (min-width: 320px) and (max-width: 575.98px) {
    display: flex;
    position: fixed;
    width: 100%;
    margin: 20px 0 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 16px;
    box-shadow: 0px -2px 7px rgb(0 0 0 / 25%);
    flex-direction: row-reverse;
    z-index: 9;
  }
}

.dmDiv {
  display: flex;
  flex-direction: column;
}
.titleDiv {
  display: flex;
  flex-direction: column;
}

.divTitle {
  font-size: 25px;
  color: var(--matterColorText);
  font-weight: 400;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 3px 0 3px 0;
  @media (max-width: 575.98px) {
    font-size: 20px;
  }
}
.divLabel {
  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 20px 0 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: left;

    font-size: 14px;
    margin: 15px 0 10px 0;
  }
}
.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  flex-basis: 30%;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.termLabel {
  display: flex;
  cursor: pointer;
}
.checkBox {
  flex-shrink: 0;
  fill: none;
}
.inputBox {
  display: none;
}

.boxDiv {
  composes: marketplaceModalHelperText from global;
  /* margin-bottom: 20px; */
  text-align: left;
  display: flex;
  color: var(--matterColorText);

  @media (min-width: 320px) and (max-width: 575.98px) {
    margin-top: 10px;
  }
  & label {
    font-weight: 400;
    margin-bottom: 10px;
    margin-right: 20px;
    & svg {
      margin-right: 10px;
      fill: none;
      width: 16px;
      height: 16px;
    }
  }
  /* & input {
    width: auto !important;
    margin-right: 10px;
  }  */
  & span {
    font-size: 14px;
    font-weight: 600;
    & label {
      display: none;
    }
    & a {
      & span {
        font-weight: 400;
        font-size: 14px;
        color: var(--matterColorText);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.inputs {
  margin-top: 20px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 49%;

  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
    margin-left: 5px;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    /* left: 1.2rem !important; */
    left: 0.8rem !important;

    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
    @media (max-width: 767px) {
      transform: translateY(-155%) scale(0.95) !important;
    }
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.inputLabel {
  & label {
    transform: translateY(-49%) scale(0.97) !important;
    left: 0.8rem !important;
  }
}
.chargeDiv {
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin-top: 10px;
  }
  & .divLabel {
    margin: 15px 0;
    padding-bottom: 10px;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
}
.locationWrapper {
  margin-top: 24px;
  margin-bottom: 20px;
}
.locationDiv {
  border-radius: 30px;
  border: 1px solid var(--matterColorText);
  position: relative;
  height: 100%;
  min-height: 347px;
  display: flex;
  flex-direction: column;
}
.locationText {
  padding: 10px 10px;
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 18px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }
  & input {
    border: 1px solid var(--matterColorText);
    border-radius: 30px;

    padding: 10px 20px;
  }
}
.map {
  width: 100% !important;
  height: 100% !important;
  padding: 0 10px 10px;
}
.cancelButton {
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
  flex-basis: 30%;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.lowerDiv {
  display: flex;
  align-items: center;
  margin: 50px 0;

  /* flex-direction: column; */
  @media (min-width: 320px) and (max-width: 575.98px) {
    display: flex;
    position: fixed;
    width: 100%;
    margin: 20px 0 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 16px;
    box-shadow: 0px -2px 7px rgb(0 0 0 / 25%);
    flex-direction: row-reverse;
    z-index: 9;
  }
}

.pricingDiv {
  & .divLabel {
    padding-bottom: 0;
  }
}
.tip {
  composes: h5 from global;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 10px 0 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: left;
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}
.priceFieldDiv {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    justify-content: space-between;
    /* flex-direction: row; */
  }
}
.priceInputs,
.priceInputsForShow,
.priceInputsForCurrency {
  margin-top: 20px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 100%;
  @media (min-width: 768px) {
    flex-basis: 49%;
    /* flex-basis: 32%; */

    /* margin: 8px 0 0; */
    margin: 8px 0;
  }
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
    margin-left: 5px;
    z-index: 1;
  }
  & input {
    text-transform: uppercase;

    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.priceInputsForCurrency {
  /* margin: 8px 0 0; */
}
.priceInputsForShow {
  cursor: not-allowed !important;
  & > input {
    cursor: not-allowed !important;
    opacity: 0.4 !important;
  }
}
.flex {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
    gap: 10px;
  }
}
.currencySelectorDiv {
  width: 15%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.CurrencyFieldAndPRicetypeDiv {
  width: 85%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 767px) {
    width: 100%;
    gap: 10px;
  }
}
.processingFee {
  color: var(--marketplaceColorDark);
  cursor: pointer;
}

.errMsg {
  font-size: 12px;
  margin-left: 15px;
  margin-top: -22px;
  margin-bottom: 0;
  color: var(--failColor);
}
.checkboxContainerGroup {
  & > ul {
    display: flex;
    flex-wrap: wrap;
    & > li {
      flex-basis: 22%;
      @media (max-width: 767.98px) {
        flex-basis: 33%;
      }
      @media (max-width: 375.98px) {
        flex-basis: 44%;
        margin-right: 15px;
      }
    }
  }
}
.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
}
.checkboxDiv {
  flex-basis: 22%;
  @media (max-width: 767.98px) {
    flex-basis: 33%;
  }
  @media (max-width: 375.98px) {
    flex-basis: 44%;
    margin-right: 15px;
  }
}

.splprWrapper {
  margin-top: 16px;
}

.specialPrReq {
  & input,
  & svg {
    display: none;
  }

  & .checkboxLabel {
    margin-left: -12px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & svg {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.ShippingTypeMain {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 5px;
}
.AddNewBtn {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  width: 140px;
  border: none;
  border-radius: var(--borderRadius);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 9px;
  margin: 10px 0;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
    outline: none;
    box-shadow: var(--boxShadowButton);
  }
}
.Removebtn {
  color: var(--matterColorText);
  width: 140px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  transition: all var(--transitionStyleButton);
  &:hover {
    color: var(--failColor);
  }
}

.sizeHeading {
  display: flex;

  & .spanText {
    flex-grow: 1;
  }
}

.info {
  display: flex;
  flex-direction: column;
  color: var(--marketplaceColor);
  padding: 0px 0px 16px;

  & svg {
    width: 24px;
    height: 24px;
  }

  & .infoChild {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    & > :last-child {
      color: #000;
      font-weight: 400 !important;
    }
  }
}

.quoteContainer {
  display: flex;
  margin: 30px 0 0;
}

.emailLabel {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
