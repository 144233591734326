.priceType {
  /* border-radius: 10px; */
  @media (min-width: 415px) {
    min-width: 100px;
    margin-left: 30px;
  }  @media (max-width: 319px) {
    display: none;
  }
  & > div{
    border-radius: 50px;
    width: max-content;
    border: 2px solid #000; 
    & > div{
      & > span{
        display: none !important;
      }
      & > div{
        & > svg{
          fill:#000;
        }
      }
    }
  }
}
:global(.css-tr4s17-option){
 &:hover{
      border-radius: 0 !important;
    }
}
:global(.css-1nmdiq5-menu){
  border-radius: 8px !important;
 margin: 8px auto 10px 13px;
 &>div{
  
  &>div:hover{
    border-radius: 5px;
  }
  
 }
 
}
